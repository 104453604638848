<template>
  <nav
    class="nav-main w-full top-0 left-0 fixed z-40 lg:border-cyan-500 lg:border-b lg:px-7 before:w-full before:h-full before:absolute before:-z-10 before:left-0 before:backdrop-blur-md before:transition-all before:duration-500 lg:before:hidden lg:backdrop-blur-md transition-all duration-500"
    :class="{
      'lg:bg-slate-900' : navFullSize,
      'lg:bg-slate-900/80' : !navFullSize,
      'before:!bg-black' : isMobileNavOpen,
      'hidden lg:block' : isMobileNavHidden,
    }"
  >
    <div
      class="flex flex-col lg:flex-row lg:min-h-0 5xl:container 5xl:mx-auto "
      :class="{ 'min-h-screen h-screencalc pb-4 overflow-auto scrollbar-hide': isMobileNavOpen }"
    >
      <div
        class="flex justify-center lg:items-center border-b border-transparent bg-cyan-500 transition-all lg:!bg-transparent lg:border-0"
        :class="{
          '!bg-black border-cyan-500' : navFullSize || isMobileNavOpen,
          '!bg-black/80 border-cyan-500' : !navFullSize,
          '!border-transparent' : isMobileNavOpen,
        }"
      >
        <transition name="fade" mode="out-in">
          <BalanceMainNav v-if="isUserLogged" class="lg:hidden"/>
          <div v-else class="grow w-14 flex items-center lg:hidden">
            <NuxtLink to="https://t.me/metawinlfg" target="_blank" class="top-[2px] py-2 px-4">
              <img src="~/assets/img/logos/telegram.svg" alt="Telegram logo" class="relative w-6">
            </NuxtLink>
          </div>
        </transition>

        <div class="grow-0 text-center">
          <NuxtLink
            to="/"
            class="inline-block mx-1 w-[138px] lg:w-[165px]"
          >
            <img
              src="~/assets/img/logos/MetaWin-logo-white.svg"
              alt="MetaWin logo"
              width="165"
              height="24"
              class="inline-block w-full lg:py-5 transition-all"
              :class="{
                'py-3' : (!navFullSize && !isMobileNavOpen) || navFullSize,
                '!py-4' : isMobileNavOpen,
              }"
            >
          </NuxtLink>
        </div>
        <NavigationBurgerButton
          v-if="!mobileCondensed"
          :is-mobile-nav-open="isMobileNavOpen"
        />

        <NuxtLink v-if="mobileCondensed" to="/games" class="grow w-14 flex justify-end items-center lg:hidden animate-fade-in">
          <span class="icon-ico-house text-xl px-3"/>
        </NuxtLink>

        <div class="justify-center hidden lg:flex">
          <NuxtLink to="https://t.me/metawinlfg" target="_blank" class="ml-0 lg:ml-3 xl:ml-4 top-1">
            <img src="~/assets/img/logos/telegram.svg" alt="Telegram logo" class="relative w-6">
          </NuxtLink>
        </div>
      </div>

      <div
        class="grow flex-col justify-evenly animate-fade-in lg:flex-row lg:flex lg:items-center lg:justify-end"
        :class="{ 'hidden': !isMobileNavOpen, 'flex': isMobileNavOpen }"
      >
        <BalanceMainNav v-if="isUserLogged" class="hidden lg:inline-block" hover-enabled/>

        <ul class="text-center mt-3 lg:flex lg:mt-0 lg:items-stretch lg:text-left lg:h-full empty:hidden">
          <li
            v-for="item in navLinksDisplay"
            :key="item.label"
            :class="[item.animClass, item.showDesktop ? 'lg:flex lg:self-stretch': 'lg:hidden']"
          >
            <NuxtLink
              :to="item.to"
              class="nav-main-link lg:flex lg:self-stretch pb-2 px-2 lg:pb-0 lg:px-0 xl:px-2 lg:mx-3 group relative"
              :class="[isParentActive(item.to, item.id) ? 'router-link-active' : '']"
            >
              <span class="inline-block py-1 my-2 text-2xl text-white/90 uppercase tracking-wider lg:whitespace-nowrap lg:text-base lg:my-0 lg:py-0 lg:mx-0 lg:flex lg:self-center transition-all duration-100 group-hover:text-cyan-500">
                {{ item.label }}
              </span>
              <div
                v-if="item.showNotifications"
                class="absolute w-4 h-4 rounded-full bg-cyan-500 -top-2 -right-1 border-[3px] border-slate-900 animate-fade-in"
                :class="[!notificationAvailable ? 'hidden' : 'inline-block']"
              />
            </NuxtLink>
          </li>
        </ul>

        <UserFlagSwitch class="lg:hidden self-center"/>

        <div v-if="showCurrencySwitch" class="lg:hidden flex justify-center py-3">
          <SwitchCompetitionValue/>
        </div>

        <div v-if="isUserLogged" class="flex justify-center lg:hidden">
          <button
            class="py-3 text-slate-100 uppercase tracking-wider hover:text-white transition-all"
            @click.prevent.stop="logout()"
          >
            Log out
          </button>
        </div>

        <div v-if="isUserLogged && userData" class="hidden lg:flex ml-3 lg:border-l lg:border-white/30 lg:pl-4 lg:ml-0">
          <UserFlagSwitch/>
          <NavigationOldAccountTooltip
            :user-data="userData"
            :main-nav-links="navLinksDisplay"
            :show-currency-switch="showCurrencySwitch"
          />

          <NuxtLink
            class="relative h-10 flex items-center ml-0 xl:ml-3 cursor-pointer px-2 transition-all hover:opacity-100"
            :class="[!notificationAvailable ? 'opacity-50' : 'opacity-100']"
            to="/notifications"
          >
            <span
              class="absolute w-4 h-4 rounded-full bg-cyan-500 top-1 right-0 border-[3px] border-slate-900 animate-fade-in"
              :class="[!notificationAvailable ? 'hidden' : 'inline-block']"
            />
            <span class="icon-ico-bell text-2xl inline-block"/>
          </NuxtLink>
          <NuxtLink
            class="relative h-10 flex items-center ml-0 xl:ml-3 cursor-pointer px-2 transition-all hover:opacity-100"
            :class="[itemCount < 1 ? 'opacity-50' : 'opacity-100']"
            to="/notifications/?tab=gifts"
          >
            <span
              class="absolute w-4 h-4 rounded-full bg-cyan-500 top-1 right-0 border-[3px] border-slate-900 animate-fade-in"
              :class="[itemCount < 1 ? 'hidden' : 'inline-block']"
            />
            <span class="icon-ico-gift text-2xl inline-block"/>
          </NuxtLink>
        </div>

        <div v-if="!isUserLogged && !blockedRoute" class="block mx-auto text-center lg:text-left lg:mx-0 lg:flex justify-center lg:pl-4">
          <NuxtLink
            to="/winners"
            class="nav-main-link hover:cursor-pointer max-lg:hidden hover:text-cyan-500 text-2xl text-white/90 uppercase tracking-wider mr-8 lg:text-base flex items-center transition-all duration-100 group-hover:text-cyan-500"
            :class="{'router-link-active text-cyan-500' : route.name === 'winners'}"
          >
          <span class="inline-block py-1 my-2 text-2xl text-white/90 uppercase tracking-wider lg:whitespace-nowrap lg:text-base lg:my-0 lg:py-0 lg:mx-0 lg:flex lg:self-center transition-all duration-100">
            Winners
          </span>
          </NuxtLink>
          <ButtonConnect
            size="sm"
            hover-enabled
            :hover-tooltip-classes="['lg:w-[296px]', 'lg:-right-4']"
          />
          <button
            v-if="!isUserLogged"
            class="p-3 block mt-4 mx-auto py-1 my-3 text-white/60 hover:text-white tracking-wider transition-all duration-100 lg:hidden"
            @click.prevent.stop="showWhyConnectModal = true"
          >
            Why connect?
          </button>
        </div>

        <div v-if="!isUserLogged" class="lg:hidden">
          <ul class="text-center lg:hidden empty:hidden">
            <li
              v-for="item in navLinksMobileDisplay"
              :key="item.label"
              :class="item.animClass"
            >
              <NuxtLink
                :to="item.to"
                :target="item.target"
                class="nav-main-link lg:flex lg:self-stretch pb-2 px-2 lg:pb-0 lg:px-2 lg:mx-3 group relative"
              >
                <span class="inline-block py-1 my-3 text-2xl text-white/90 uppercase tracking-wider transition-all duration-100 group-hover:text-cyan-500">
                  {{ item.label }}
                </span>
              </NuxtLink>
            </li>
          </ul>
        </div>

        <ul v-if="!isUserLogged" class="text-center animate-slide-down-fade-in06s lg:hidden">
          <li class="inline-block">
            <ButtonLinkIcon
              class="lg:px-2 2xl:p-4 text-cyan-500 text-shadow-cyan"
              href="https://www.instagram.com/metawin.eth"
              title="MetaWin Instagram"
              ico="icon-ico-instagram"
            />
          </li>
          <li class="inline-block">
            <ButtonLinkIcon
              class="lg:px-2 2xl:p-4 text-cyan-500 text-shadow-cyan"
              href="https://twitter.com/Meta_Winners"
              title="MetaWin Twitter"
              ico="icon-ico-twitter"
            />
          </li>
          <li class="inline-block">
            <ButtonLinkIcon
              class="lg:px-2 2xl:p-4 text-cyan-500 text-shadow-cyan"
              href="https://discord.gg/the-arena"
              title="ARENA Discord"
              ico="icon-ico-discord"
            />
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
/**
 * TODO: Discuss the effect of this file and delete it and its children if required
 * at a later date.
 */
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/store/auth';
import { useUserStore } from '@/store/user';
import { useUiStore } from '@/store/ui';
import { useNotificationsStore } from '@/store/notifications';
import { useMinigamesStore } from '@/store/minigames';

const authStore = useAuthStore();
const {
  isUserLogged,
} = storeToRefs(authStore);
const {
  logout,
} = authStore;

const userStore = useUserStore();
const {
  userData,
} = storeToRefs(userStore);

const uiStore = useUiStore();
const {
  isMobileNavOpen,
  showWhyConnectModal,
  chatState,
  isMobileNavHidden,
} = storeToRefs(uiStore);
const {
  resizeHandler,
} = uiStore;

const notificationsStore = useNotificationsStore();
const {
  notificationAvailable,
  itemCount,
} = storeToRefs(notificationsStore);

const minigamesStore = useMinigamesStore();
const {
  showGameContainer,
} = storeToRefs(minigamesStore);

const config = useRuntimeConfig().public;
const route = useRoute();

const mq = inject('mq');
const scrollPosition = ref(0);
const activateScrollAt = ref(100);
const mobileCondensed = ref(null);
const navLinksMobile = ref([
  {
    label: 'Winners',
    to: '/winners',
    animClass: 'animate-slide-down-fade-in01s',
  },
  {
    label: 'Corporate',
    to: 'https://metawin.xyz',
    target: '_blank',
    animClass: 'animate-slide-down-fade-in02s',
  },
  {
    label: 'About',
    to: 'https://metawin.xyz/about',
    target: '_blank',
    animClass: 'animate-slide-down-fade-in03s',
  },
  {
    label: 'Privacy',
    to: '/privacy-policy',
    animClass: 'animate-slide-down-fade-in04s',
  },
  {
    label: 'Terms',
    to: '/terms-and-conditions',
    animClass: 'animate-slide-down-fade-in05s',
  },
  {
    label: 'NFT Presale',
    to: '/presale',
    animClass: 'animate-slide-down-fade-in06s',
    disabled: !config.PRESALE_ACTIVE,
  },
]);

const navLinks = computed(() => {
  return [
    {
      label: 'Competitions',
      to: '/',
      showDesktop: true,
      animClass: 'animate-slide-down-fade-in01s',
      guestVisible: false,
      id: 'competitions',
    },
    {
      label: 'Instant Win',
      to: '/games',
      showDesktop: true,
      animClass: 'animate-slide-down-fade-in01s',
      guestVisible: false,
      id: 'instant-win',
    },
    {
      label: 'Live Games',
      to: '/live-games',
      showDesktop: true,
      animClass: 'animate-slide-down-fade-in01s',
      guestVisible: false,
      id: 'live-games',
      disabled: !config.LIVE_CASINO_ACTIVE,
    },
    {
      label: 'Winners',
      to: '/winners',
      showDesktop: false,
      animClass: 'animate-slide-down-fade-in01s',
      guestVisible: false,
      id: 'winners',
    },
    {
      label: 'Activity',
      to: '/activity',
      showDesktop: false,
      animClass: 'animate-slide-down-fade-in01s',
      guestVisible: false,
      id: 'activity',
    },
    {
      label: 'Notifications',
      to: '/notifications',
      showNotifications: true,
      showDesktop: false,
      animClass: 'animate-slide-down-fade-in01s',
      guestVisible: false,
      id: 'notifications',
    },
    {
      label: 'My profile',
      to: '/',
      showDesktop: false,
      animClass: 'animate-slide-down-fade-in01s',
      guestVisible: false,
      id: 'my-profile',
    },
    {
      label: 'Settings',
      to: '/account',
      showDesktop: false,
      animClass: 'animate-slide-down-fade-in01s',
      guestVisible: false,
      id: 'settings',
    },
    {
      label: 'Make It Rain',
      to: '/account/tip',
      showDesktop: false,
      animClass: 'animate-slide-down-fade-in01s',
      guestVisible: false,
      id: 'tip',
      disabled: !userData.value?.tags?.includes('TipsEnabled'),
    },
    {
      label: 'Referrals',
      to: '/referrals',
      showDesktop: false,
      animClass: 'animate-slide-down-fade-in01s',
      guestVisible: false,
      id: 'referal',
      disabled: !config.REFERRALS_ACTIVE,
    },
    {
      label: 'NFT Presale',
      to: '/presale',
      showDesktop: true,
      showInTooltip: true,
      animClass: 'animate-slide-down-fade-in01s',
      guestVisible: false,
      id: 'presale',
      moveToTooltipAtBelow: 'lg',
      icon: 'icon-ico-thunder',
      disabled: !config.PRESALE_ACTIVE,
    },
  ];
});
const blockedRoute = computed(() => ['account-alias-token-confirm',].includes(route.name));
const navLinksDisplay = computed(() => {
  let navLinksLocal = navLinks.value.filter(item => !item.disabled);
  if (!isUserLogged.value) {
    navLinksLocal = navLinksLocal.filter(item => item.guestVisible);
  } else if (userData.value?.displayName) {
    const myProfileIndex = navLinksLocal.findIndex(item => item.id === 'my-profile');
    navLinksLocal[myProfileIndex].to = `/${userData.value.displayName}`;
  }
  return navLinksLocal;
});
const navLinksMobileDisplay = computed(() => navLinksMobile.value.filter(item => !item.disabled));
const navFullSize = computed(() => (showGameContainer.value && (chatState.value === 'minified' || chatState.value === 'fullscreen')) ? false : scrollPosition.value <= activateScrollAt.value);
const showCurrencySwitch = computed(() => route.path === '/' || route.path.startsWith('/competition') || route.path.startsWith('/mw'));

function updateScroll() {
  scrollPosition.value = window.scrollY;
}

function isParentActive(path, id) {
  return (route.path.split('/')[1] === 'competition' && id === 'competitions') ? true : route.path.startsWith(path + '/');
}

function rearrangeNav() {
  // Move nav links (marked with moveToTooltipAtBelow) to tooltip if screen is smaller than breakpoint
  navLinks.value.forEach((item) => {
    if (item.moveToTooltipAtBelow) {
      item.showDesktop = !mq[`${item.moveToTooltipAtBelow}Minus`];
      item.showInTooltip = mq[`${item.moveToTooltipAtBelow}Minus`];
    }
  });
}

function checkPage() {
  mobileCondensed.value = route.name === 'games-id';
}

watch(
  () => mq.current,
  () => {
    rearrangeNav();
  }
);

watch(
  () => navFullSize.value,
  () => {
    resizeHandler();
  }
);

watch(
  () => route,
  () => {
    checkPage();
  }
);

onMounted(() => {
  rearrangeNav();
  window.addEventListener('scroll', updateScroll);
  resizeHandler();
  checkPage();
});
</script>
